import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  // cities = ["London", "New York", "Moscow", "Karachi"];

  countries = [
	{
      name: 'Australia',
      cities: ['Sydney', 'Adelaide', 'Melbourne', 'Brisbane', 'Perth', 'Canberra']
    },
	{
      name: 'Canada',
      cities: ['Toronto', 'Calgary', 'Vancouver', 'Ottawa', 'Winnipeg', 'Barrie']
    },	
	{
      name: 'France',
      cities: ['Paris', 'Marseille', 'Nice', 'Lyon', 'Lille']
    },
    {
      name: 'India',
      cities: ['New Delhi', 'Mumbai', 'Kolkata', 'Chennai', 'Bangalore', 'Jaipur', 'Lucknow']
    },
	{
      name: 'Italy',
      cities: ['Rome', 'Turin', 'Venice', 'Milan', 'Naples', 'Florence']
    },
	{
      name: 'United Kingdom',
      cities: ['London', 'Warwick', 'Birmingham', 'Manchester', 'Reiding']
    },
    {
      name: 'United States',
      cities: ['New York', 'Chicago', 'Washington', 'Dallas']
    },    
    {
      name: 'Pakistan',
      cities: ['Lahore', 'Karachi', 'Islamabad']
    },
	{
      name: 'Philippines',
      cities: ['Manila']
    },
	{
      name: 'Serbia',
      cities: ['Belgrade']
    }
  ];

  countryControl: FormControl;
  cityControl: FormControl;

  cities$: Observable<string>;

  constructor(private router: Router) { }

  ngOnInit() {
    this.cityControl = new FormControl('');
    this.cityControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.router.navigate([value]);
      });

    this.countryControl = new FormControl('');

    this.cities$ = this.countryControl.valueChanges.pipe(
      map(country => country.cities)
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
