<mat-card class="mat-elevation-z5" *ngIf="data$ | async as data">
    <mat-progress-bar *ngIf="loading" mode="indeterminate" class="top-bar">
    </mat-progress-bar>
    <mat-card-header>
        <mat-card-title>{{data.city}}'s Weather</mat-card-title>
        <mat-card-subtitle>{{data.dt }} {{data.tm }}</mat-card-subtitle>		
		<mat-card-subtitle>
			<img src="../../assets/icons/max.png" class="weather-icon-sm" /> {{data.temp_max | number:'0.0-0'}}&#176; C  | 
			<img src="../../assets/icons/min.png" class="weather-icon-sm" />{{data.temp_min | number:'0.0-0'}}&#176; C
		</mat-card-subtitle>		
    </mat-card-header>
    <mat-card-content>
		<div class="flex-row">
            <div class="temp">
                <span class="big-text">{{data.temp | number:'0.0-0'}}&#176; C</span>
                <span>Feels like </span>
                <span>{{data.feels_like | number: '0.0-0'}}&#176; C</span>
            </div>
            <div class="outlook">
                <img [src]="data.image" class="image" />
                <span>{{data.cond_desc | titlecase}}</span>
            </div>
        </div>		
		<div class="flex-row">
			<img [src]="data.cityImage" class="cityimage" />			
		</div>
        <div class="flex-row">
			<div class="sunrise">
				<img src="../../assets/icons/sunrise.png" class="weather-icon" /><br/>{{data.sunrise}}
			</div>
			<div class="sunset">
				<img src="../../assets/icons/sunset.png" class="weather-icon" /><br/>{{data.sunset}}
			</div>
		</div>
		<div class="flex-row topMargin">
			<div class="pressure">
				<img src="../../assets/icons/pressure.png" class="weather-icon" /><br/>{{data.pressure | number:'0.0-0'}} Hg
			</div>
			<div class="humidity">
				<img src="../../assets/icons/humidity.png" class="weather-icon" /><br/>{{data.humidity | number:'0.0-0'}}%
			</div>
		</div>
    </mat-card-content>
    <mat-card-actions>
        <mat-card-subtitle></mat-card-subtitle>
    </mat-card-actions>
</mat-card>