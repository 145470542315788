import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WeatherService {
	constructor(private http: HttpClient) { }

	getWeatherForCity(city: string): Observable<any> {
		//const path = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&APPID=695ed9f29c4599b7544d0db5c211d499`;
		const path = `https://weather.isoftlogix.com/iweather.php?city=${city}`;
		return this.http.get<any>(path).pipe(
			map(data => ({
				...data,
				image: `https://openweathermap.org/img/wn/${data.cond_icon}@2x.png`,
				cityImage: `../../assets/bg/${city}.jpg`
			})),
			delay(500)
		);
	}	
	
}
